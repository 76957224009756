const Twitter = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <g clipPath="url(#clip0_4279_1274)">
        <path
          d="M16.6549 27.3111C23.4499 27.3111 27.1675 21.6802 27.1675 16.7985C27.1675 16.6402 27.164 16.4784 27.1569 16.3201C27.8801 15.7971 28.5042 15.1493 28.9999 14.4071C28.3264 14.7068 27.6113 14.9025 26.879 14.9875C27.65 14.5254 28.2273 13.7994 28.5039 12.9441C27.7786 13.3739 26.9854 13.6771 26.1582 13.8407C25.6009 13.2486 24.8641 12.8565 24.0616 12.7251C23.2592 12.5938 22.4357 12.7304 21.7187 13.1139C21.0017 13.4974 20.431 14.1065 20.0948 14.8469C19.7586 15.5873 19.6757 16.4178 19.8589 17.2101C18.3902 17.1364 16.9534 16.7549 15.6416 16.0902C14.3299 15.4256 13.1724 14.4928 12.2443 13.3521C11.7726 14.1654 11.6282 15.1278 11.8406 16.0437C12.0529 16.9596 12.6061 17.7603 13.3876 18.283C12.8009 18.2644 12.227 18.1064 11.7134 17.8222V17.8679C11.7129 18.7214 12.008 19.5488 12.5485 20.2093C13.089 20.8698 13.8415 21.3228 14.6782 21.4913C14.1348 21.64 13.5644 21.6616 13.0112 21.5546C13.2473 22.2886 13.7066 22.9306 14.3252 23.3909C14.9437 23.8513 15.6905 24.1071 16.4614 24.1226C15.1527 25.1506 13.536 25.7082 11.8717 25.7056C11.5766 25.7051 11.2818 25.687 10.9888 25.6514C12.6795 26.7361 14.6462 27.3122 16.6549 27.3111Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4279_1274">
          <rect
            width="18.0111"
            height="18.0111"
            fill="white"
            transform="translate(10.9888 10.9888)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Twitter
