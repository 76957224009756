const ProductTag = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="35"
      height="47"
      viewBox="0 0 35 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 44.9922V0H35V44.9922C35 46.4214 33.5443 47.3893 32.2263 46.8365L18.2737 40.9834C17.7788 40.7758 17.2212 40.7758 16.7263 40.9834L2.77367 46.8365C1.45572 47.3893 0 46.4214 0 44.9922Z"
        fill="#21D2C5"
      />
    </svg>
  )
}

export default ProductTag
