const Phone = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.44013 2C7.88448 2 8.29392 2.24103 8.50964 2.62954L10.006 5.32493C10.2019 5.67784 10.2111 6.10471 10.0305 6.46576L8.58903 9.34879C8.58903 9.34879 9.00678 11.4966 10.7551 13.2449C12.5035 14.9932 14.644 15.4037 14.644 15.4037L17.5266 13.9625C17.8879 13.7818 18.315 13.7911 18.6681 13.9874L21.3711 15.4902C21.7593 15.706 22 16.1153 22 16.5594V19.6625C22 21.2428 20.5321 22.3842 19.0348 21.8789C15.9595 20.8413 11.1858 18.8656 8.16015 15.8398C5.13442 12.8142 3.1587 8.04049 2.12104 4.96522C1.61581 3.46786 2.75719 2 4.33745 2H7.44013Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M21.9235 10C22.059 9.09114 22.0145 8.16069 21.7902 7.26604C21.4939 6.08417 20.8835 4.96491 19.9593 4.04067C19.0351 3.11644 17.9158 2.50615 16.734 2.2098C15.8394 1.98547 14.9089 1.94104 14 2.07651"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9617 10C18.119 8.94528 17.7916 7.83226 16.9797 7.02035C16.1677 6.20843 15.0547 5.88103 14 6.03826"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Phone
