const AnkerSolix = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="160"
      height="28"
      viewBox="0 0 160 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M75.2261 10.1053C75.2261 11.1506 74.4861 11.7691 73.3652 11.7691H69.6056V8.56043H73.3652C74.7859 8.56043 75.2261 9.53815 75.2261 10.1053ZM75.4692 14.1972C77.0519 12.9818 77.7487 11.7178 77.638 10.0945C77.4759 7.72586 75.561 6.13774 73.376 6.13774H67.1154V19.8312H69.6029V14.1972H72.5441L76.0607 19.8312H78.9992L75.4692 14.1972Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.166 14.7103L51.38 19.8231L54.3375 19.8285L49.7784 12.5766L48.166 14.7103Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.4328 6.13501H50.2727L43.7609 14.7913V6.13501H41.2599V18.6158C41.2599 19.0722 41.5273 19.6394 42.243 19.8042C42.5266 19.869 43.0938 19.7988 43.4341 19.3531C43.4773 19.2991 53.4301 6.13501 53.4301 6.13501H53.4328Z"
        fill="#00A9E0"
      />
      <path
        d="M63.8879 11.7664H58.9723V14.1945H63.8879V11.7664Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M65.1518 6.13501H55.3962V18.6104C55.3962 19.2829 55.9391 19.8285 56.6143 19.8285H65.1491V17.4085H57.8892V8.5604H65.1491V6.13501H65.1518Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36.2633 6.13501V14.7913L29.7569 6.13501H26.5807V19.8231H29.0763V9.41388C32.0257 13.3193 36.5631 19.318 36.5902 19.3531C36.9332 19.7988 37.5003 19.869 37.7839 19.8042C38.4997 19.6394 38.7643 19.0722 38.7643 18.6158V6.13501H36.266H36.2633Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.4011 6.87236L10 19.8285H12.79L17.6111 10.1053L22.4294 19.8285H25.1924L18.8427 6.91828C18.605 6.44022 18.1215 6.13503 17.5868 6.13503C17.0844 6.13503 16.6226 6.42132 16.4011 6.87236Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8717 17.892H18.2214C18.2052 17.892 18.1917 17.8785 18.1971 17.865L18.5158 14.3457C18.5185 14.3187 18.4861 14.3025 18.4672 14.3268L14.9156 18.9696C14.9048 18.9885 14.9156 19.0128 14.9399 19.0128H16.5874C16.6063 19.0128 16.6117 19.029 16.6117 19.0425L16.2903 22.5618C16.2903 22.5888 16.3227 22.5996 16.3389 22.578L19.8906 17.9352C19.9041 17.919 19.8906 17.892 19.8717 17.892Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M150 6.14571H147.043L144.166 10.1187L145.646 12.166L150 6.14571Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M145.273 13.3031L143.512 10.8696L140.099 6.15387H137.141L142.089 12.9898L137.141 19.8284H140.099L143.569 15.0344L147.043 19.8338H150L145.273 13.3031Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M122.37 6.14575H119.875V18.5995C119.875 19.2693 120.417 19.8149 121.095 19.8149H129.646V17.3949H122.367V6.14575H122.37Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M132.147 19.8149H134.643V17.3976V6.14575H132.147V19.8149Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M110.397 6C109.93 6 109.473 6.04591 109.033 6.13234L109.662 8.55503C109.9 8.51452 110.146 8.49561 110.397 8.49561C110.702 8.49561 110.999 8.52532 111.288 8.58474L110.862 6.01621C110.708 6.0054 110.554 6 110.397 6Z"
        fill="#00CE7C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M115.642 8.37398L113.86 10.1295C114.203 10.5482 114.473 11.0289 114.648 11.5529L116.66 9.89997C116.388 9.34629 116.045 8.83583 115.642 8.37668V8.37398Z"
        fill="#00CE7C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M117.244 11.6151L114.821 12.2444C114.862 12.4821 114.881 12.7278 114.881 12.979C114.881 13.2842 114.851 13.5813 114.791 13.8703L117.36 13.4436C117.371 13.2896 117.376 13.1357 117.376 12.979C117.376 12.5118 117.33 12.0553 117.244 11.6151Z"
        fill="#00CE7C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M112.639 6.36725L111.972 8.78183C112.488 8.9763 112.958 9.26259 113.365 9.62181L114.281 7.18291C113.776 6.8453 113.225 6.56981 112.639 6.36995V6.36725Z"
        fill="#00CE7C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M113.757 15.9473C113.698 16.0175 113.633 16.0823 113.568 16.1472C112.755 16.9574 111.637 17.4598 110.397 17.4598C107.92 17.4598 105.914 15.453 105.914 12.9763C105.914 11.7393 106.416 10.6185 107.226 9.80549C107.329 9.70285 107.437 9.60562 107.548 9.51379C107.966 9.17078 108.447 8.90069 108.971 8.72514L107.318 6.71298C106.764 6.98577 106.254 7.32878 105.795 7.73121C105.681 7.83115 105.571 7.93378 105.463 8.04181C104.199 9.30312 103.418 11.0479 103.418 12.9763C103.418 16.8305 106.543 19.9554 110.397 19.9554C112.326 19.9554 114.068 19.1748 115.332 17.9108C115.653 17.5894 115.942 17.2383 116.196 16.8602C116.536 16.3551 116.809 15.8041 117.009 15.218L114.597 14.5509C114.403 15.0668 114.116 15.5395 113.757 15.9446V15.9473Z"
        fill="#00A9E0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.1899 11.7798H97.0791L93.5518 11.7771C92.4282 11.7771 91.6909 11.1613 91.6909 10.1161C91.6909 9.5462 92.1338 8.57119 93.5518 8.57119L98.9346 8.6009L100.796 6.1512H93.5356C91.356 6.1512 89.4438 7.73661 89.2817 10.1026C89.198 11.3018 89.5572 12.3038 90.3891 13.2356C90.9427 13.8541 91.7422 14.1998 92.5714 14.1998H97.0764H97.171C98.5916 14.1998 99.0319 15.1748 99.0319 15.7447C99.0319 16.7873 98.2918 17.4058 97.171 17.4058H91.121L89.2682 19.8311H98.1487C98.9778 19.8311 99.7773 19.4854 100.331 18.8669C101.163 17.9351 101.522 16.9331 101.438 15.7339C101.276 13.3652 99.3641 11.7825 97.1845 11.7825L97.1899 11.7798Z"
        fill="#00A9E0"
      />
    </svg>
  )
}

export default AnkerSolix
