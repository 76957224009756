const FaceBook = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="22338"
    >
      <path
        d="M893.59920796 2.14540998H129.23560357c-69.91130485 0-127.00553746 57.09423262-127.00553751 127.0055375v764.3636044c0 69.91130485 57.09423262 127.00553746 127.00553751 127.00553746H516.07815975V655.81611336H393.73337498V497.35048896H516.07815975v-80.39800136c0-123.50997324 90.88469656-219.0554238 207.4035388-219.05542379h111.85808825v179.43901739h-100.20620452c-26.79933422 0-34.9556524 15.1474492-34.95565241 36.1208409v83.89356686h135.16185693V655.81611336H700.17792987v365.86916449h193.42127809c69.91130485 0 128.17072595-57.09423262 128.17072598-127.0055375V129.15094748c0-69.91130485-58.25942109-127.00553746-128.17072598-127.0055375"
        p-id="22339"
      ></path>
    </svg>
  )
}

export default FaceBook
