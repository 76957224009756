const AnkerWork = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1638 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="7472"
      width="128"
      height="87"
    >
      <path
        d="M470.698667 622.592l60.416 69.290667h53.248l-79.530667-91.477334 81.578667-86.698666h-54.954667l-60.757333 64.512v-128.682667h-39.936v242.346667h39.936zM804.864 604.501333c0-27.989333 22.869333-50.858667 50.858667-50.858666h12.970666l-12.288-39.936h-1.024c-50.176 0-90.794667 40.618667-90.794666 90.794666v87.381334h39.936v-87.381334zM1227.093333 512c-50.176 0-90.794667 40.618667-90.794666 90.794667 0 50.176 40.618667 90.794667 90.794666 90.794666 50.176 0 90.794667-40.618667 90.794667-90.794666 0-50.176-40.618667-90.794667-90.794667-90.794667z m0 141.312c-27.989333 0-50.517333-22.528-50.517333-50.517333s22.528-50.517333 50.517333-50.517334 50.517333 22.528 50.517334 50.517334-22.528 50.517333-50.517334 50.517333zM327.68 513.706667a81.578667 81.578667 0 0 0-81.578667 81.578666v96.597334h39.936v-96.597334c0-22.869333 18.773333-41.301333 41.642667-41.301333 22.869333 0 41.642667 18.432 41.642667 41.301333v96.597334h39.936v-95.914667-0.682667a81.578667 81.578667 0 0 0-81.578667-81.578666zM1333.930667 604.501333v87.381334h39.936v-87.381334c0-27.989333 22.869333-50.858667 50.858666-50.858666h7.850667v-39.936h-7.850667c-50.176 0-90.794667 40.618667-90.794666 90.794666zM1488.554667 691.882667v-69.290667l60.416 69.290667h53.248l-79.530667-91.477334L1604.266667 513.706667h-54.954667l-60.757333 64.512v-128.682667h-39.936v242.346667zM927.061333 691.541333v0.341334h41.984v-0.341334l39.253334-129.024 39.253333 129.365334v0.341333h41.301333v-0.341333l73.386667-242.005334h-41.301333l-52.906667 173.738667-40.96-134.826667a19.456 19.456 0 0 0-19.114667-13.994666c-8.874667 0-16.384 5.802667-19.114666 13.994666l-40.96 134.485334-52.906667-173.738667h-41.301333l73.386666 242.005333z"
        p-id="7473"
      ></path>
      <path
        d="M657.749333 512c-50.176 0-90.794667 40.618667-90.794666 90.794667 0 50.176 40.618667 90.794667 90.794666 90.794666 38.912 0 72.021333-24.234667 84.992-58.709333h-45.738666c-9.216 11.264-23.210667 18.432-38.912 18.432-22.186667 0-41.301333-14.336-47.786667-34.474667h137.216A91.272533 91.272533 0 0 0 657.749333 512z m-47.786666 74.752c6.826667-20.138667 25.6-34.474667 47.786666-34.474667s41.301333 14.336 47.786667 34.474667h-95.573333zM133.12 461.482667a19.797333 19.797333 0 0 0-18.090667-11.946667 19.797333 19.797333 0 0 0-18.090666 11.946667L0 691.882667h43.008l27.648-65.194667h88.746667l27.648 65.194667h43.008L133.12 461.482667z m-45.738667 124.928l27.648-65.536 27.648 65.536H87.381333z"
        p-id="7474"
      ></path>
    </svg>
  )
}

export default AnkerWork
