const Linkin = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <g clipPath="url(#clip0_4279_1285)">
        <path
          d="M27.6667 10.9888H12.3185C11.5833 10.9888 10.9888 11.5692 10.9888 12.2868V27.6983C10.9888 28.416 11.5833 28.9999 12.3185 28.9999H27.6667C28.4019 28.9999 28.9999 28.416 28.9999 27.7018V12.2868C28.9999 11.5692 28.4019 10.9888 27.6667 10.9888ZM16.3323 26.3369H13.6588V17.7394H16.3323V26.3369ZM14.9955 16.568C14.1372 16.568 13.4442 15.875 13.4442 15.0202C13.4442 14.1653 14.1372 13.4723 14.9955 13.4723C15.8504 13.4723 16.5434 14.1653 16.5434 15.0202C16.5434 15.8715 15.8504 16.568 14.9955 16.568ZM26.3369 26.3369H23.6669V22.1578C23.6669 21.1623 23.6493 19.8783 22.2774 19.8783C20.8879 19.8783 20.6768 20.9653 20.6768 22.0874V26.3369H18.0103V17.7394H20.5713V18.9144H20.6064C20.9617 18.239 21.8342 17.5248 23.1322 17.5248C25.8374 17.5248 26.3369 19.3049 26.3369 21.6196V26.3369Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_4279_1285">
          <rect
            width="18.0111"
            height="18.0111"
            fill="white"
            transform="translate(10.9888 10.9888)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Linkin
