const SalesTax = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 3.5C1.75 2.80964 2.30964 2.25 3 2.25H13C13.6904 2.25 14.25 2.80964 14.25 3.5V14.6893L13.1632 13.6026C12.5327 12.972 11.5289 12.9164 10.8326 13.4735L8.40617 15.4146C8.31487 15.4876 8.18513 15.4876 8.09383 15.4146L5.61521 13.4317C4.9587 12.9065 4.02146 12.9224 3.38311 13.4695L1.75 14.8693V3.5Z"
        stroke="#666666"
        strokeWidth="1.5"
      />
      <path
        d="M10.1112 8.868C10.1112 9.16133 10.0339 9.43333 9.87919 9.684C9.72985 9.92933 9.50585 10.132 9.20719 10.292C8.91385 10.4467 8.56452 10.532 8.15919 10.548V11.196H7.64719V10.54C7.08185 10.492 6.62585 10.324 6.27919 10.036C5.93252 9.74267 5.74852 9.34533 5.72719 8.844H7.18319C7.21519 9.164 7.36985 9.36933 7.64719 9.46V8.196C7.23119 8.08933 6.89785 7.98533 6.64719 7.884C6.40185 7.78267 6.18585 7.62 5.99919 7.396C5.81252 7.172 5.71919 6.86533 5.71919 6.476C5.71919 5.99067 5.89785 5.60133 6.25519 5.308C6.61785 5.01467 7.08185 4.84933 7.64719 4.812V4.164H8.15919V4.812C8.71919 4.85467 9.16185 5.01733 9.48719 5.3C9.81252 5.58267 9.99385 5.97467 10.0312 6.476H8.56719C8.53519 6.188 8.39919 6.00133 8.15919 5.916V7.156C8.60185 7.27867 8.94319 7.388 9.18319 7.484C9.42319 7.58 9.63652 7.74 9.82319 7.964C10.0152 8.18267 10.1112 8.484 10.1112 8.868ZM7.16719 6.412C7.16719 6.54533 7.20719 6.65733 7.28719 6.748C7.37252 6.83867 7.49252 6.91867 7.64719 6.988V5.876C7.49785 5.90267 7.38052 5.96133 7.29519 6.052C7.20985 6.13733 7.16719 6.25733 7.16719 6.412ZM8.15919 9.484C8.31919 9.45733 8.44452 9.39333 8.53519 9.292C8.63119 9.19067 8.67919 9.06533 8.67919 8.916C8.67919 8.77733 8.63385 8.66533 8.54319 8.58C8.45785 8.48933 8.32985 8.412 8.15919 8.348V9.484Z"
        fill="#666666"
      />
    </svg>
  )
}

export default SalesTax
