const Soundcore = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 160 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6821_1677)">
        <path
          d="M2.04585 24.612C2.05929 24.5673 2.08322 24.5271 2.11537 24.4951C2.14751 24.4631 2.18679 24.4405 2.22945 24.4293C2.27212 24.4182 2.31675 24.4189 2.35908 24.4314C2.40142 24.4439 2.44005 24.4678 2.47128 24.5008C3.03169 25.0981 4.43658 26.2926 6.88515 26.5343C8.66149 26.71 10.6576 26.4258 10.7694 25.1983C10.8209 24.6353 10.5766 24.1218 9.68203 23.9625C8.9481 23.8252 6.72576 23.6056 5.20778 22.8778C3.39802 22.0073 2.25149 20.5217 2.34018 18.6969C2.34018 17.3596 3.4263 13.6345 9.03166 13.0674C13.1447 12.6555 15.371 14.2482 15.8979 14.6849C15.9349 14.7156 15.9633 14.7565 15.98 14.8032C15.9968 14.8499 16.0013 14.9006 15.9931 14.9499C15.8183 15.9687 15.5355 17.6712 15.4069 18.4415C15.3998 18.4854 15.3827 18.5268 15.3572 18.5621C15.3316 18.5974 15.2985 18.6255 15.2605 18.644C15.2225 18.6625 15.1809 18.6709 15.1392 18.6684C15.0976 18.6659 15.0571 18.6526 15.0213 18.6296C14.2 18.1202 11.8298 16.8941 8.5638 17.2978C8.22318 17.3403 6.66792 17.5161 6.63579 18.7175C6.62293 19.1651 6.91984 19.7747 8.13963 20.0521C8.60749 20.1578 10.1808 20.3638 10.5252 20.4105C12.8992 20.7359 15.281 21.8439 15.281 24.8248C15.281 28.1352 12.4108 30.3472 9.03681 30.8731C4.29776 31.6104 1.61526 28.9206 1.0677 28.3069C1.03577 28.2709 1.01353 28.2264 1.00334 28.1781C0.993161 28.1297 0.995405 28.0793 1.00986 28.0323L2.04585 24.612Z"
          fill="#767676"
        />
        <path
          d="M135.288 14.5661L135.41 13.7964C135.416 13.7577 135.413 13.7182 135.402 13.6806C135.392 13.643 135.373 13.6081 135.347 13.5785C135.321 13.5489 135.289 13.5253 135.254 13.5092C135.218 13.4932 135.179 13.4851 135.14 13.4856H131.188C131.124 13.4846 131.061 13.5064 131.012 13.5471C130.962 13.5878 130.929 13.6447 130.918 13.7076L127.997 29.9892L132.524 30L134.119 21.1662C134.5 19.0779 136.126 17.373 138.612 17.373C139.305 17.3957 139.983 17.5727 140.598 17.891C140.659 17.9245 140.731 17.9327 140.799 17.9142C140.866 17.8956 140.924 17.8516 140.96 17.7915L142.96 14.4019C142.996 14.3411 143.006 14.2687 142.989 14.2002C142.972 14.1318 142.928 14.0728 142.868 14.0359C141.374 13.1398 138.313 12.0176 135.288 14.5661Z"
          fill="#767676"
        />
        <path
          d="M122.988 22.0337C122.986 23.2239 122.566 24.3649 121.818 25.2065C121.07 26.0481 120.055 26.5216 118.998 26.523C117.94 26.5219 116.925 26.0486 116.177 25.207C115.429 24.3654 115.008 23.2241 115.007 22.0337C115.008 20.8432 115.429 19.702 116.177 18.8604C116.925 18.0187 117.94 17.5454 118.998 17.5443C120.055 17.5458 121.07 18.0192 121.818 18.8608C122.566 19.7024 122.986 20.8435 122.988 22.0337ZM118.998 13C116.877 13.0025 114.844 13.9514 113.344 15.6385C111.844 17.3256 111 19.6131 110.997 21.9993C111 24.3858 111.843 26.6737 113.343 28.361C114.843 30.0484 116.877 30.9975 118.998 31C121.118 30.9971 123.152 30.0479 124.651 28.3605C126.151 26.6732 126.994 24.3855 126.997 21.9993C126.994 19.6133 126.15 17.326 124.651 15.639C123.151 13.9519 121.118 13.0029 118.998 13Z"
          fill="#767676"
        />
        <path
          d="M109.876 15.0149C108.202 13.5733 106.07 12.8607 103.916 13.0226C101.887 13.1776 99.9764 14.0933 98.5295 15.6049C97.0827 17.1164 96.1944 19.1243 96.0249 21.2666C95.9299 22.5058 96.0788 23.7523 96.4622 24.9277C96.8455 26.1032 97.4551 27.182 98.2524 28.0963C99.0498 29.0106 100.018 29.7406 101.095 30.2404C102.173 30.7401 103.336 30.9987 104.513 31C106.479 31.0026 108.385 30.2827 109.903 28.9637C109.951 28.9222 109.982 28.8644 109.993 28.8005C110.004 28.7367 109.993 28.6708 109.962 28.6147L108.042 25.133C108.022 25.0955 107.993 25.0637 107.959 25.0401C107.924 25.0166 107.885 25.002 107.844 24.9976C107.803 24.9932 107.762 24.999 107.724 25.0147C107.685 25.0303 107.651 25.0554 107.624 25.0877C107.198 25.5737 106.676 25.9539 106.095 26.2008C105.513 26.4477 104.887 26.5551 104.262 26.5153C103.234 26.447 102.265 25.9884 101.532 25.224C100.8 24.4595 100.353 23.4409 100.276 22.3562C100.235 21.7414 100.314 21.1243 100.508 20.5432C100.702 19.9622 101.008 19.4295 101.405 18.9784C101.802 18.5273 102.283 18.1675 102.818 17.9213C103.353 17.675 103.93 17.5477 104.513 17.5472C105.081 17.5469 105.643 17.6672 106.166 17.9009C106.689 18.1346 107.162 18.4768 107.557 18.9074C107.585 18.9386 107.619 18.9626 107.657 18.9774C107.695 18.9923 107.735 18.9977 107.775 18.9932C107.815 18.9887 107.854 18.9743 107.888 18.9513C107.922 18.9283 107.95 18.8973 107.971 18.8607L109.933 15.3708C109.966 15.3141 109.978 15.2469 109.968 15.1815C109.957 15.1161 109.925 15.0569 109.876 15.0149Z"
          fill="#767676"
        />
        <path
          d="M30.7357 22.0337C30.7343 23.2239 30.2873 24.3649 29.4925 25.2065C28.6978 26.0481 27.6203 26.5216 26.4964 26.523C25.3725 26.5216 24.295 26.0481 23.5003 25.2065C22.7056 24.3649 22.2585 23.2239 22.2571 22.0337C22.2585 20.8435 22.7056 19.7024 23.5003 18.8608C24.295 18.0192 25.3725 17.5458 26.4964 17.5443C27.6203 17.5458 28.6978 18.0192 29.4925 18.8608C30.2873 19.7024 30.7343 20.8435 30.7357 22.0337ZM26.4964 13C24.2433 13.0029 22.0833 13.9519 20.49 15.639C18.8967 17.326 18.0002 19.6133 17.9971 21.9993C17.9998 24.3855 18.8962 26.6732 20.4895 28.3605C22.0828 30.0479 24.2431 30.9971 26.4964 31C28.75 30.9975 30.9106 30.0484 32.5042 28.361C34.0978 26.6737 34.9943 24.3858 34.9971 21.9993C34.994 19.6131 34.0973 17.3256 32.5037 15.6385C30.9101 13.9514 28.7497 13.0025 26.4964 13Z"
          fill="#767676"
        />
        <path
          d="M54.7496 14H51.0838C51.0236 13.9991 50.965 14.0214 50.9187 14.0629C50.8723 14.1043 50.8412 14.1623 50.831 14.2263C50.611 15.5896 49.4746 22.6282 49.4746 22.6282C48.9463 25.8073 46.6874 26.5585 45.3677 26.5585C43.0392 26.5585 40.6716 25.1202 41.3416 20.9582C41.6171 19.2418 42.2365 15.4424 42.4198 14.319C42.4262 14.2796 42.4243 14.2391 42.4145 14.2004C42.4046 14.1618 42.387 14.126 42.3629 14.0955C42.3387 14.0651 42.3087 14.0407 42.2748 14.0242C42.241 14.0077 42.2042 13.9994 42.167 14H38.5012C38.4412 13.999 38.3827 14.0211 38.3364 14.0623C38.2901 14.1036 38.2589 14.1612 38.2484 14.225L37.1486 20.9473C36.102 27.2578 40.6527 31 45.3651 31C49.151 31 52.7208 28.388 53.5677 23.2157C53.9267 21.0345 54.7774 15.6769 54.9936 14.3177C54.9996 14.2791 54.9978 14.2396 54.9884 14.2018C54.979 14.164 54.9621 14.1288 54.939 14.0987C54.9158 14.0686 54.8869 14.0442 54.8543 14.0271C54.8216 14.0101 54.7859 14.0009 54.7496 14Z"
          fill="#767676"
        />
        <path
          d="M55.2633 30H59.1281C59.1914 30.001 59.253 29.9789 59.3019 29.9377C59.3507 29.8966 59.3836 29.839 59.3947 29.7753L60.8487 21.3631C61.4017 18.1865 63.7833 17.4362 65.1746 17.4362C67.6295 17.4362 70.1257 18.8727 69.4153 23.0311C69.1248 24.7454 68.4625 28.5566 68.2665 29.6813C68.2599 29.7208 68.2618 29.7612 68.2722 29.7998C68.2826 29.8384 68.3011 29.8742 68.3266 29.9046C68.352 29.935 68.3837 29.9593 68.4194 29.9758C68.4551 29.9923 68.4939 30.0006 68.5331 30H72.398C72.4613 30.001 72.5228 29.9789 72.5717 29.9377C72.6205 29.8966 72.6534 29.839 72.6645 29.7753L73.8373 23.0407C74.9408 16.739 70.143 13 65.1746 13C61.1832 13 57.4196 15.6102 56.5267 20.7763C56.1482 22.9549 55.2326 28.3224 55.0007 29.6827C54.9943 29.7217 54.9963 29.7616 55.0066 29.7998C55.0168 29.8379 55.0351 29.8733 55.0601 29.9035C55.0851 29.9337 55.1162 29.9579 55.1513 29.9746C55.1863 29.9913 55.2246 29.9999 55.2633 30Z"
          fill="#767676"
        />
        <path
          d="M90.5277 12.1708L89.8954 15.723C88.2688 14.3466 86.1941 13.6408 84.0811 13.7452C81.9768 13.8522 79.9863 14.7498 78.4948 16.264C77.0034 17.7783 76.1169 19.8017 76.0069 21.9427C75.9517 23.1105 76.1299 24.2776 76.5307 25.3732C76.9316 26.4689 77.5468 27.4704 78.339 28.3171C79.1313 29.1639 80.0842 29.8382 81.14 30.2993C82.1958 30.7605 83.3325 30.9989 84.4815 31C86.473 30.9972 88.4004 30.2828 89.927 28.9814C91.4536 27.68 92.4826 25.8744 92.8343 23.8797C94.4683 14.3214 94.5888 13.612 94.9931 11.3098C95.0004 11.2681 94.9976 11.2252 94.9852 11.1848C94.9727 11.1444 94.9509 11.1076 94.9216 11.0776C94.8922 11.0476 94.8562 11.0253 94.8165 11.0124C94.7769 10.9996 94.7348 10.9966 94.6938 11.0039C93.3438 11.2361 92.0102 11.5576 90.7014 11.9664C90.657 11.9814 90.6174 12.0083 90.5869 12.0443C90.5563 12.0802 90.5359 12.124 90.5277 12.1708ZM84.4374 26.625C83.3152 26.6236 82.2393 26.1692 81.4459 25.3616C80.6525 24.554 80.2063 23.4591 80.2053 22.3172C80.2074 21.175 80.6547 20.0804 81.4491 19.2735C82.2436 18.4666 83.3202 18.0134 84.4426 18.0134C85.5646 18.0148 86.6403 18.4689 87.4336 19.2763C88.227 20.0836 88.6733 21.1781 88.6747 22.3198C88.673 23.4613 88.2265 24.5555 87.4332 25.3626C86.6398 26.1696 85.5644 26.6236 84.4426 26.625H84.4374Z"
          fill="#767676"
        />
        <path
          d="M91.8689 5.07038C94.7596 4.38526 97.7318 4.02579 100.719 4.00003C100.759 3.99946 100.799 4.00703 100.836 4.02221C100.873 4.03738 100.906 4.05978 100.932 4.08779C100.958 4.11581 100.977 4.14875 100.988 4.18426C100.998 4.21977 101 4.25697 100.993 4.29319C100.808 5.24253 100.557 6.54616 100.356 7.59654C100.344 7.65298 100.312 7.70415 100.264 7.74141C100.216 7.77868 100.155 7.79977 100.092 7.80113C97.135 7.8692 94.1982 8.26849 91.3481 8.98999C91.3033 9.00189 91.2559 9.0032 91.2104 8.99379C91.1649 8.98438 91.1228 8.96456 91.0881 8.93621C91.0534 8.90785 91.0272 8.8719 91.012 8.83176C90.9968 8.79162 90.9931 8.74861 91.0013 8.70681L91.6715 5.26873C91.6803 5.22202 91.7036 5.17856 91.7385 5.14343C91.7735 5.1083 91.8187 5.08296 91.8689 5.07038Z"
          fill="#767676"
        />
        <path
          d="M156.823 23.951H159.783C159.925 23.3106 159.997 22.6567 159.997 22.0008C159.996 20.783 159.748 19.5782 159.268 18.459C158.788 17.3398 158.086 16.3296 157.204 15.4892C156.323 14.6489 155.28 13.9959 154.14 13.5698C152.999 13.1436 151.783 12.9531 150.567 13.0098C148.334 13.1239 146.222 14.0623 144.641 15.6435C143.06 17.2246 142.121 19.336 142.007 21.5692C141.95 22.7856 142.14 24.0009 142.566 25.1417C142.992 26.2825 143.645 27.3252 144.486 28.2066C145.326 29.088 146.336 29.79 147.455 30.2701C148.574 30.7503 149.779 30.9985 150.997 31C152.42 30.9987 153.822 30.6601 155.088 30.0119C156.354 29.3638 157.449 28.4246 158.282 27.2714C158.323 27.2129 158.339 27.1407 158.328 27.0702C158.317 26.9996 158.279 26.9362 158.221 26.8934L155.518 24.8731C155.488 24.8516 155.455 24.836 155.42 24.8273C155.385 24.8187 155.348 24.8171 155.312 24.8228C155.276 24.8284 155.242 24.8411 155.211 24.8601C155.18 24.8791 155.153 24.9041 155.132 24.9336C154.664 25.5946 154.044 26.1337 153.324 26.5058C152.605 26.8778 151.807 27.072 150.997 27.0721C149.998 27.0691 149.022 26.772 148.191 26.2179C147.36 25.6638 146.711 24.8773 146.324 23.9565H156.823V23.951ZM150.997 16.9322C151.993 16.9329 152.967 17.2259 153.797 17.7749C154.628 18.3239 155.279 19.1046 155.67 20.0203H146.331C146.721 19.1051 147.371 18.3244 148.2 17.7754C149.03 17.2264 150.002 16.9332 150.997 16.9322Z"
          fill="#767676"
        />
      </g>
    </svg>
  )
}

export default Soundcore
