const Chat = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.03131 8.6053C3.74668 4.81896 6.77968 2.06448 10.3086 2.06448C13.8942 2.06448 16.9677 4.90793 17.6186 8.78806C17.1232 9.12024 16.7971 9.68534 16.7971 10.3266V12.981C16.7971 14.0033 17.6258 14.832 18.6482 14.832C19.6705 14.832 20.4992 14.0033 20.4992 12.981V10.3266C20.4992 9.49743 19.954 8.7956 19.2026 8.56C18.4547 4.01172 14.8058 0.464478 10.3086 0.464478C5.75067 0.464478 2.06402 4.10836 1.38595 8.74515L1.38647 8.74523C0.853712 9.07023 0.498047 9.65688 0.498047 10.3266V12.981C0.498047 13.9811 1.29131 14.7961 2.28301 14.8309C3.54616 17.4825 5.90296 19.4576 8.73802 19.9977C8.95654 20.2646 9.28861 20.4349 9.66047 20.4349H10.7116C11.3697 20.4349 11.9032 19.9014 11.9032 19.2433C11.9032 18.5852 11.3697 18.0517 10.7116 18.0517H9.66048C9.34044 18.0517 9.04988 18.1779 8.8358 18.3832C6.6503 17.8954 4.79037 16.3416 3.75034 14.1906C4.03064 13.8661 4.20018 13.4433 4.20018 12.981V10.3266C4.20018 9.54521 3.71601 8.87689 3.03131 8.6053ZM2.60018 10.3266V12.981C2.60018 13.1196 2.48778 13.232 2.34911 13.232C2.21045 13.232 2.09805 13.1196 2.09805 12.981V10.3266C2.09805 10.1879 2.21045 10.0755 2.34911 10.0755C2.48778 10.0755 2.60018 10.1879 2.60018 10.3266ZM18.8992 12.981V10.3266C18.8992 10.1879 18.7868 10.0755 18.6482 10.0755C18.5095 10.0755 18.3971 10.1879 18.3971 10.3266V12.981C18.3971 13.1196 18.5095 13.232 18.6482 13.232C18.7868 13.232 18.8992 13.1196 18.8992 12.981Z"
        fill="#777777"
      />
    </svg>
  )
}

export default Chat
