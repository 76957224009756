const UserAnkerMakeMob = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M48 0H0V48H48V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M24 24C29.5229 24 34 19.5229 34 14C34 8.47716 29.5229 4 24 4C18.4771 4 14 8.47716 14 14C14 19.5229 18.4771 24 24 24Z"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 40.9714V42H42V40.9714C42 37.1311 42 35.2109 41.1281 33.7441C40.3611 32.4538 39.1372 31.4048 37.6319 30.7473C35.9206 30 33.6804 30 29.2 30H18.8C14.3196 30 12.0794 30 10.3681 30.7473C8.86278 31.4048 7.63893 32.4538 6.87195 33.7441C6 35.2109 6 37.1311 6 40.9714Z"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default UserAnkerMakeMob
