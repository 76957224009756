const ArrowDown = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5772"
      width="32"
      height="32"
    >
      <path
        d="M225.408 368.384L496 640.641l270.592-272.257-48.64-49.024L496 542.592 274.176 319.36l-48.768 49.024z m0 0z"
        fill=""
        p-id="5773"
      ></path>
    </svg>
  )
}

export default ArrowDown
