const Hassle = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.13058 12C4.13058 8.34867 6.6174 5.27815 9.98974 4.38977L9.05115 5.68904C8.74974 6.10628 8.84364 6.68887 9.26088 6.99028C9.67812 7.29169 10.2607 7.1978 10.5621 6.78056L12.7554 3.74447C12.9603 3.46075 12.989 3.08611 12.8297 2.77447C12.6704 2.46283 12.3499 2.26672 11.9999 2.26672C6.62434 2.26672 2.2666 6.62447 2.2666 12C2.2666 14.4944 3.20616 16.7716 4.7492 18.4935C5.09271 18.8769 5.68193 18.9092 6.06525 18.5656C6.44858 18.2221 6.48086 17.6329 6.13735 17.2496C4.8888 15.8563 4.13058 14.0178 4.13058 12Z"
        fill="#777777"
        fill-opacity="0.85"
      />
      <path
        d="M19.5476 5.85393C19.2224 5.45497 18.6353 5.3952 18.2363 5.72043C17.8374 6.04565 17.7776 6.63272 18.1028 7.03168C19.2075 8.38678 19.8692 10.1149 19.8692 12C19.8692 15.4312 17.6733 18.3495 14.6101 19.4261L15.4823 18.5167C15.8385 18.1452 15.8262 17.5552 15.4547 17.1989C15.0832 16.8426 14.4933 16.855 14.137 17.2265L11.3272 20.1562C11.0688 20.4257 10.9961 20.8235 11.1426 21.167C11.2891 21.5104 11.6265 21.7333 11.9999 21.7333C17.3754 21.7333 21.7332 17.3756 21.7332 12C21.7332 9.67027 20.9135 7.52946 19.5476 5.85393Z"
        fill="#777777"
        fill-opacity="0.85"
      />
      <path
        d="M13.9756 13.0807C15.5113 12.6315 16.3868 11.0043 15.931 9.44624C15.7917 8.96977 15.5439 8.55494 15.2232 8.22145L13.9831 10.5264L12.4807 9.68986L13.7208 7.38487C13.2718 7.28979 12.7948 7.30108 12.3252 7.43845C10.7895 7.88765 9.91402 9.51486 10.3698 11.0729C10.5091 11.5494 10.7569 11.9642 11.0776 12.2977L8.54869 16.1994L10.0511 17.0359L12.58 13.1343C13.029 13.2294 13.506 13.2181 13.9756 13.0807Z"
        fill="#777777"
        fill-opacity="0.85"
      />
    </svg>
  )
}

export default Hassle
