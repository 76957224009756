const Service = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="17"
      {...props}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2757_9025)">
        <path
          d="M15.3346 1.16666H0.667969V15.8333H15.3346V1.16666Z"
          fill="white"
          fillOpacity="0.01"
        />
        <path
          d="M12.668 11.1667C14.1407 11.1667 15.3346 10.0883 15.3346 8.75806C15.3346 7.72328 14.6122 6.84092 13.598 6.5"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 11.1667C2.52723 11.1667 1.33333 10.0883 1.33333 8.75806C1.33333 7.72328 2.05577 6.84092 3.06993 6.5"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 11.1667V11V10.1667V8.50001V5.83334C4 3.6242 5.94011 1.83334 8.33333 1.83334C10.7266 1.83334 12.6667 3.6242 12.6667 5.83334V11.1667C12.6667 13.3758 10.7266 15.1667 8.33333 15.1667"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2757_9025">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Service
