const AnkerSM = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.50458 0.607293L0 11.2727H2.39851L6.54386 3.26618L10.6871 11.2727H13.0645L7.60152 0.642224C7.50248 0.449497 7.34904 0.287183 7.15858 0.173674C6.96811 0.0601654 6.74825 2.24813e-07 6.5239 0C6.31173 9.29554e-05 6.10382 0.0570211 5.92369 0.164342C5.74355 0.271663 5.59838 0.425098 5.50458 0.607293Z" />
      <path d="M8.4874 9.67769H7.06921C7.066 9.67771 7.06283 9.67708 7.05989 9.67585C7.05695 9.67462 7.05432 9.67281 7.05215 9.67054C7.04998 9.66828 7.04833 9.66561 7.04731 9.6627C7.04629 9.65979 7.04591 9.6567 7.04621 9.65365L7.32252 6.75642C7.32297 6.75168 7.32179 6.74692 7.31918 6.74287C7.31656 6.73881 7.31264 6.73568 7.30801 6.73394C7.30338 6.7322 7.29828 6.73195 7.29348 6.73322C7.28869 6.73449 7.28445 6.73721 7.2814 6.74098L4.22742 10.5645C4.2248 10.5677 4.22319 10.5716 4.22277 10.5757C4.22235 10.5798 4.22313 10.5839 4.22503 10.5876C4.22693 10.5913 4.22987 10.5944 4.23351 10.5966C4.23716 10.5987 4.24137 10.5999 4.24566 10.5999H5.66385C5.66706 10.5999 5.67023 10.6005 5.67317 10.6017C5.67611 10.603 5.67874 10.6048 5.68091 10.607C5.68308 10.6093 5.68473 10.612 5.68575 10.6149C5.68677 10.6178 5.68715 10.6209 5.68685 10.6239L5.41054 13.5212C5.41009 13.5259 5.41127 13.5307 5.41388 13.5347C5.4165 13.5388 5.42042 13.5419 5.42505 13.5436C5.42968 13.5454 5.43478 13.5456 5.43958 13.5444C5.44437 13.5431 5.44861 13.5404 5.45166 13.5366L8.50564 9.71301C8.50822 9.70975 8.5098 9.70586 8.5102 9.70179C8.5106 9.69772 8.50981 9.69362 8.50792 9.68995C8.50603 9.68629 8.5031 9.6832 8.49948 9.68103C8.49585 9.67887 8.49167 9.67771 8.4874 9.67769Z" />
    </svg>
  )
}

export default AnkerSM
