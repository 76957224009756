const Whatsapp = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_579_39769)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5039 3.48801C18.2461 1.24044 15.2461 0 12.0508 0C5.46484 0 0.101563 5.33506 0.101563 11.8911C0.097656 13.987 0.648438 16.0324 1.69531 17.8367L0 24L6.33594 22.3435C8.07813 23.2923 10.0469 23.79 12.0469 23.7939H12.0508C18.6367 23.7939 23.9961 18.4588 24 11.8989C24 8.72197 22.7578 5.73558 20.5039 3.48801ZM12.0508 21.7835H12.0469C10.2656 21.7835 8.51563 21.3052 6.99219 20.407L6.62891 20.1931L2.86719 21.173L3.87109 17.5256L3.63672 17.1523C2.64063 15.5774 2.11719 13.7576 2.11719 11.8911C2.11719 6.44329 6.57422 2.01037 12.0547 2.01037C14.707 2.01037 17.1992 3.04083 19.0742 4.90732C20.9492 6.77771 21.9805 9.25859 21.9805 11.8989C21.9805 17.3506 17.5234 21.7835 12.0508 21.7835ZM17.4961 14.3798C17.1992 14.232 15.7305 13.5126 15.457 13.4154C15.1836 13.3143 14.9844 13.2677 14.7852 13.5632C14.5859 13.8626 14.0156 14.5314 13.8398 14.7297C13.668 14.9242 13.4922 14.9514 13.1953 14.8036C12.8945 14.6559 11.9336 14.3409 10.793 13.326C9.90625 12.5405 9.30469 11.5645 9.13281 11.269C8.95703 10.9695 9.11328 10.8101 9.26172 10.6623C9.39844 10.5301 9.5625 10.3163 9.71094 10.1413C9.85938 9.97019 9.91016 9.84576 10.0117 9.64744C10.1094 9.44913 10.0586 9.27414 9.98438 9.12638C9.91016 8.97861 9.3125 7.51264 9.0625 6.91769C8.82031 6.3383 8.57422 6.41996 8.39062 6.4083C8.21875 6.40052 8.01953 6.40052 7.82031 6.40052C7.62109 6.40052 7.29688 6.4744 7.02344 6.77382C6.75 7.06935 5.98047 7.78872 5.98047 9.25081C5.98047 10.7129 7.05078 12.1283 7.19922 12.3266C7.34766 12.5211 9.30469 15.523 12.3008 16.8101C13.0117 17.1173 13.5664 17.3001 14 17.4362C14.7148 17.6617 15.3672 17.6306 15.8828 17.5567C16.457 17.4712 17.6484 16.8373 17.8984 16.1413C18.1445 15.4491 18.1445 14.8542 18.0703 14.7297C17.9961 14.6053 17.7969 14.5314 17.4961 14.3798Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_579_39769">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export default Whatsapp
