const ForMultiDevice = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 34 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="33" height="49" rx="3.5" stroke="black" />
      <rect x="9.5" y="33.5" width="15" height="6" rx="0.5" stroke="black" />
      <rect x="11" y="35" width="12" height="2" rx="0.5" fill="black" />
      <rect x="10.5" y="9.5" width="13" height="4" rx="2" stroke="black" />
      <rect x="10.5" y="19.5" width="13" height="4" rx="2" stroke="black" />
      <rect x="13" y="11" width="8" height="1" rx="0.5" fill="black" />
      <rect x="13" y="21" width="8" height="1" rx="0.5" fill="black" />
    </svg>
  )
}

export default ForMultiDevice
