const Anker = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 80 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4536_10284)">
        <path
          d="M75.653 15.2494C77.4586 13.8572 78.2542 12.4063 78.1248 10.5415C77.9377 7.82372 75.7553 6 73.266 6H66.1289V21.7144H68.9661V15.2494H72.3193L76.3293 21.7144H79.6777L75.653 15.2494ZM75.3734 10.5531C75.3734 11.7556 74.5319 12.4651 73.2521 12.4651H68.9661V8.77864H73.2516C74.8695 8.77864 75.3734 9.9017 75.3734 10.5531Z"
          fill="#00BEFA"
        />
        <path
          d="M44.5195 15.8402L48.1792 21.708L51.5553 21.7128L46.3559 13.3906L44.5195 15.8402Z"
          fill="#00BEFA"
        />
        <path
          d="M50.5257 6H46.9211L39.4993 15.9304L39.4981 6H36.6484V20.3221C36.6484 20.8493 36.9522 21.5004 37.7679 21.6887C38.0928 21.7638 38.7391 21.6834 39.1285 21.1697C39.1762 21.1069 50.5257 6 50.5257 6Z"
          fill="#00BEFA"
        />
        <path
          d="M56.8438 15.252L62.4481 15.253V12.4688H56.8438V15.252Z"
          fill="#00BEFA"
        />
        <path
          d="M63.8894 6H52.7656V20.3175C52.7657 20.688 52.9119 21.0433 53.1722 21.3053C53.4326 21.5673 53.7856 21.7144 54.1537 21.7144H63.8894V18.9372H55.6105V8.78269H63.8894V6Z"
          fill="#00BEFA"
        />
        <path
          d="M30.9488 6L30.9477 15.9304L23.5258 6H19.9023V21.7063H22.7476V9.76213C26.1124 14.2408 31.2862 21.1272 31.3183 21.1697C31.7077 21.6834 32.354 21.7638 32.6789 21.6887C33.4946 21.5004 33.7984 20.8493 33.7984 20.3221V6H30.9488Z"
          fill="#00BEFA"
        />
        <path
          d="M8.29908 6.84658L1 21.7144H4.18042L9.67716 10.5531L15.1711 21.7144H18.3235L11.0796 6.89527C10.9483 6.62661 10.7448 6.40034 10.4923 6.24211C10.2397 6.08387 9.94818 6 9.65069 6C9.36936 6.00013 9.09367 6.07949 8.85481 6.2291C8.61595 6.3787 8.42345 6.5926 8.29908 6.84658Z"
          fill="#00BEFA"
        />
        <path
          d="M12.2566 19.4886H10.3761C10.3718 19.4886 10.3676 19.4877 10.3637 19.486C10.3598 19.4843 10.3563 19.4818 10.3535 19.4786C10.3506 19.4755 10.3484 19.4717 10.3471 19.4677C10.3457 19.4636 10.3452 19.4593 10.3456 19.4551L10.712 15.4163C10.7126 15.4097 10.711 15.403 10.7076 15.3974C10.7041 15.3917 10.6989 15.3874 10.6927 15.3849C10.6866 15.3825 10.6798 15.3822 10.6735 15.3839C10.6671 15.3857 10.6615 15.3895 10.6575 15.3947L6.60788 20.7248C6.60441 20.7293 6.60227 20.7347 6.60171 20.7404C6.60115 20.7461 6.60219 20.7519 6.60471 20.757C6.60722 20.7622 6.61112 20.7665 6.61596 20.7695C6.62079 20.7726 6.62637 20.7742 6.63207 20.7742H8.51258C8.51684 20.7741 8.52105 20.775 8.52494 20.7767C8.52884 20.7784 8.53234 20.781 8.53521 20.7841C8.53808 20.7873 8.54027 20.791 8.54162 20.7951C8.54298 20.7991 8.54348 20.8034 8.54309 20.8077L8.1767 24.8465C8.1761 24.8531 8.17766 24.8597 8.18113 24.8654C8.1846 24.871 8.18979 24.8754 8.19594 24.8778C8.20208 24.8802 8.20884 24.8806 8.2152 24.8788C8.22156 24.8771 8.22718 24.8733 8.23122 24.868L12.2808 19.5378C12.2842 19.5333 12.2863 19.5279 12.2868 19.5222C12.2874 19.5165 12.2863 19.5108 12.2838 19.5057C12.2813 19.5006 12.2774 19.4963 12.2726 19.4932C12.2678 19.4902 12.2623 19.4886 12.2566 19.4886Z"
          fill="#00BEFA"
        />
      </g>
      <defs>
        <clipPath id="clip0_4536_10284">
          <rect
            width="78.6784"
            height="18.8828"
            fill="white"
            transform="translate(1 6)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Anker
