const Deal = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33203 6.93767V3.5H14.6654V6.93767C13.9592 7.18933 13.4532 7.86843 13.4532 8.66667C13.4532 9.4649 13.9592 10.144 14.6654 10.3957V13.8333H1.33203V10.3957C2.03821 10.144 2.54415 9.4649 2.54415 8.66667C2.54415 7.86843 2.03821 7.18933 1.33203 6.93767Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M4.36347 7.086C4.36347 6.6 4.50147 6.225 4.77747 5.961C5.05947 5.697 5.42247 5.565 5.86647 5.565C6.31047 5.565 6.67047 5.697 6.94647 5.961C7.22247 6.225 7.36047 6.6 7.36047 7.086C7.36047 7.572 7.22247 7.947 6.94647 8.211C6.67047 8.475 6.31047 8.607 5.86647 8.607C5.42247 8.607 5.05947 8.475 4.77747 8.211C4.50147 7.947 4.36347 7.572 4.36347 7.086ZM10.4745 5.664L7.00947 12H5.52447L8.98947 5.664H10.4745ZM5.85747 6.465C5.56947 6.465 5.42547 6.672 5.42547 7.086C5.42547 7.494 5.56947 7.698 5.85747 7.698C5.99547 7.698 6.10347 7.647 6.18147 7.545C6.25947 7.443 6.29847 7.29 6.29847 7.086C6.29847 6.672 6.15147 6.465 5.85747 6.465ZM8.64747 10.578C8.64747 10.092 8.78547 9.717 9.06147 9.453C9.33747 9.189 9.69747 9.057 10.1415 9.057C10.5855 9.057 10.9455 9.189 11.2215 9.453C11.4975 9.717 11.6355 10.092 11.6355 10.578C11.6355 11.064 11.4975 11.439 11.2215 11.703C10.9455 11.967 10.5855 12.099 10.1415 12.099C9.69747 12.099 9.33747 11.967 9.06147 11.703C8.78547 11.439 8.64747 11.064 8.64747 10.578ZM10.1325 9.957C9.99447 9.957 9.88647 10.008 9.80847 10.11C9.73647 10.212 9.70047 10.368 9.70047 10.578C9.70047 10.986 9.84447 11.19 10.1325 11.19C10.2705 11.19 10.3785 11.139 10.4565 11.037C10.5345 10.935 10.5735 10.782 10.5735 10.578C10.5735 10.374 10.5345 10.221 10.4565 10.119C10.3785 10.011 10.2705 9.957 10.1325 9.957Z"
        fill="#666666"
      />
    </svg>
  )
}

export default Deal
