const XCircle = ({ ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
  >
    <path
      d="M30.7615 15.7541C30.7615 24.085 24.01 30.8418 15.6738 30.8418C10.4651 30.8418 5.8734 28.206 3.16442 24.1896C1.53798 21.784 0.586182 18.8815 0.586182 15.7541C0.586182 7.42323 7.33771 0.666473 15.6738 0.666473C24.01 0.666473 30.7615 7.42323 30.7615 15.7541Z"
      fill="#FC355D"
    />
    <path
      d="M18.0219 15.754L21.2068 12.5692C21.8553 11.9207 21.8553 10.8695 21.2068 10.221C20.5583 9.57254 19.5072 9.57254 18.8587 10.221L15.6738 13.4059L12.4889 10.221C11.8404 9.57254 10.7893 9.57254 10.1408 10.221C9.4923 10.8695 9.4923 11.9207 10.1408 12.5692L13.3257 15.754L10.1408 18.9389C9.4923 19.5874 9.4923 20.6386 10.1408 21.2871C10.465 21.6113 10.8886 21.7734 11.3175 21.7734C11.7463 21.7734 12.1699 21.6113 12.4941 21.2871L15.679 18.1022L18.8639 21.2871C19.1881 21.6113 19.6118 21.7734 20.0406 21.7734C20.4642 21.7734 20.893 21.6113 21.2173 21.2871C21.8657 20.6386 21.8657 19.5874 21.2173 18.9389L18.0219 15.754Z"
      fill="white"
    />
  </svg>
)

export default XCircle
