const LinkedIn = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <g clipPath="url(#clip0_2563_5944)">
        <path
          d="M28.5108 10H11.4758C10.6597 10 9.99988 10.6442 9.99988 11.4407V28.5461C9.99988 29.3426 10.6597 29.9907 11.4758 29.9907H28.5108C29.3268 29.9907 29.9906 29.3426 29.9906 28.55V11.4407C29.9906 10.6442 29.3268 10 28.5108 10ZM15.9307 27.0351H12.9633V17.4926H15.9307V27.0351ZM14.447 16.1924C13.4943 16.1924 12.7252 15.4233 12.7252 14.4745C12.7252 13.5257 13.4943 12.7565 14.447 12.7565C15.3958 12.7565 16.165 13.5257 16.165 14.4745C16.165 15.4194 15.3958 16.1924 14.447 16.1924ZM27.0349 27.0351H24.0715V22.3966C24.0715 21.2916 24.0519 19.8665 22.5292 19.8665C20.987 19.8665 20.7527 21.073 20.7527 22.3185V27.0351H17.7931V17.4926H20.6356V18.7967H20.6746C21.069 18.047 22.0373 17.2544 23.478 17.2544C26.4805 17.2544 27.0349 19.2301 27.0349 21.7992V27.0351Z"
          fill="#1D1D1D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2563_5944">
          <rect
            width="19.9907"
            height="19.9907"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedIn
