const FaceBook = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width="40" height="40" rx="20" fill="white" />
      <g clipPath="url(#clip0_2380_4246)">
        <path
          d="M29.9907 19.9954C29.9907 14.4751 25.5156 10 19.9954 10C14.4751 10 10 14.4751 10 19.9954C10 24.9842 13.6551 29.1194 18.4336 29.8693V22.8846H15.8957V19.9954H18.4336V17.7933C18.4336 15.2882 19.9259 13.9044 22.209 13.9044C23.3022 13.9044 24.4464 14.0997 24.4464 14.0997V16.5595H23.1861C21.9445 16.5595 21.5571 17.33 21.5571 18.1212V19.9954H24.3293L23.8861 22.8846H21.5571V29.8693C26.3356 29.1194 29.9907 24.9842 29.9907 19.9954Z"
          fill="#1D1D1D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2380_4246">
          <rect
            width="19.9907"
            height="19.9907"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default FaceBook
