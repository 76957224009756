const Guarantee = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1777_3974)">
        <path
          d="M2 3.58522L8.00287 1.83334L14 3.58522V8.17791C14 11.9541 11.5834 14.3065 8.00087 15.5002C4.41737 14.3065 2 11.9533 2 8.17624V3.58522Z"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M5 8.16667L7.33333 10.5L11.3333 6.5"
          stroke="#666666"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1777_3974">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Guarantee
