const LiveChatFooter = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.3337 4.16634C18.3337 3.7061 17.9606 3.33301 17.5003 3.33301H2.50032C2.04009 3.33301 1.66699 3.7061 1.66699 4.16634V14.6425C1.66699 15.1028 2.04009 15.4759 2.50033 15.4759H4.58366V17.4997L8.75033 15.4759H17.5003C17.9606 15.4759 18.3337 15.1028 18.3337 14.6425V4.16634Z"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 6.6665L12.5 7.49984"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66699 6.6665L6.66699 7.49984"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5003 10C12.5003 10 11.667 11.6667 9.58366 11.6667C7.50033 11.6667 6.66699 10 6.66699 10"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LiveChatFooter
