const UserNavAnkerMake = ({ ...props }) => {
  return (
    <svg { ...props } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01"/>
      <path d="M12 12C14.7614 12 17 9.76143 17 7C17 4.23858 14.7614 2 12 2C9.23857 2 7 4.23858 7 7C7 9.76143 9.23857 12 12 12Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3 20.4857V21H21V20.4857C21 18.5655 21 17.6055 20.5641 16.872C20.1806 16.2269 19.5686 15.7024 18.816 15.3737C17.9603 15 16.8402 15 14.6 15H9.4C7.1598 15 6.0397 15 5.18405 15.3737C4.43139 15.7024 3.81947 16.2269 3.43598 16.872C3 17.6055 3 18.5655 3 20.4857Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
export default UserNavAnkerMake
