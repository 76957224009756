const Countries = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5910"
    >
      <path
        d="M740.736 871.552H283.264A27.264 27.264 0 0 1 256 844.8V179.2a27.264 27.264 0 0 1 27.136-27.264h457.6A27.264 27.264 0 0 1 768 179.2v665.6a27.264 27.264 0 0 1-27.264 26.752zM283.264 163.328A16.256 16.256 0 0 0 267.008 179.2v665.6a16.256 16.256 0 0 0 16.256 16.384h457.472A16.256 16.256 0 0 0 756.992 844.8V179.2a16.256 16.256 0 0 0-16.256-16.384z"
        p-id="5911"
      ></path>
      <path
        d="M733.184 767.232H290.816a5.376 5.376 0 0 1-5.504-5.376V200.448a5.376 5.376 0 0 1 5.504-5.376h442.368a5.376 5.376 0 0 1 5.504 5.376v561.408a5.376 5.376 0 0 1-5.504 5.376z m-436.992-10.752h431.616v-550.4H296.192zM512 841.856a29.44 29.44 0 1 1 29.44-29.44 29.44 29.44 0 0 1-29.44 29.44zM512 793.6a18.688 18.688 0 1 0 18.56 18.56A18.56 18.56 0 0 0 512 793.6z"
        p-id="5912"
      ></path>
    </svg>
  )
}

export default Countries
