const MoneyBack = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10.5" r="9.2" stroke="#777777" strokeWidth="1.6" />
      <path
        d="M7.71988 14.3015C7.64988 14.2455 7.63588 14.1615 7.69188 14.0915L8.43388 13.1535C8.48988 13.0835 8.57388 13.0835 8.62988 13.1395C9.03588 13.4755 9.67988 13.8255 10.4219 13.8955V11.0255C8.82588 10.7595 7.85988 9.76552 7.85988 8.33752C7.85988 6.78352 8.92388 5.66352 10.4219 5.50952V4.78152C10.4219 4.69752 10.4779 4.64152 10.5619 4.64152H11.1079C11.1919 4.64152 11.2479 4.69752 11.2479 4.78152V5.50952C12.0599 5.57952 12.8019 5.85952 13.3899 6.25152C13.4599 6.30752 13.4739 6.36352 13.4179 6.44752L12.7739 7.39952C12.7179 7.46952 12.6479 7.46952 12.5779 7.42752C12.1719 7.17552 11.7239 6.99352 11.2479 6.92352V9.65352C12.9419 9.93352 13.8239 10.9135 13.8239 12.3555C13.8239 13.9375 12.8439 15.0435 11.2479 15.2675V16.0095C11.2479 16.0935 11.1919 16.1495 11.1079 16.1495H10.5619C10.4779 16.1495 10.4219 16.0935 10.4219 16.0095V15.2955C9.35788 15.2535 8.44788 14.8755 7.71988 14.3015ZM10.4219 9.48552V6.92352C9.79188 7.04952 9.38588 7.55352 9.38588 8.22552C9.38588 8.81352 9.62388 9.26152 10.4219 9.48552ZM11.2479 13.8535C11.9339 13.6855 12.2979 13.1955 12.2979 12.4115C12.2979 11.8795 12.0459 11.4175 11.2479 11.1795V13.8535Z"
        fill="#777777"
      />
    </svg>
  )
}

export default MoneyBack
