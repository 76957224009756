const YouApply = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 37 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.328369 3C0.328369 1.34314 1.67151 0 3.32837 0H27.6384C29.2953 0 30.6384 1.34315 30.6384 3V7.58425L27.6072 9.94562V4.03098C27.6072 3.47869 27.1594 3.03098 26.6072 3.03098H4.35913C3.80685 3.03098 3.35913 3.47869 3.35913 4.03098V29.31C3.35913 29.8623 3.80685 30.31 4.35913 30.31H26.6072C27.1594 30.31 27.6072 29.8623 27.6072 29.31V28.6102L30.6384 26.2856V30.341C30.6384 31.9979 29.2953 33.341 27.6384 33.341H3.32837C1.67151 33.341 0.328369 31.9979 0.328369 30.341V3Z"
        fill="white"
      />
      <path
        d="M9.42186 10.093C9.42186 9.54075 9.86957 9.09304 10.4219 9.09304H20.5459C21.0982 9.09304 21.5459 9.54075 21.5459 10.093V11.124C21.5459 11.6763 21.0982 12.124 20.5459 12.124H10.4219C9.86957 12.124 9.42186 11.6763 9.42186 11.124V10.093Z"
        fill="white"
      />
      <path
        d="M9.42186 16.155C9.42186 15.6027 9.86957 15.155 10.4219 15.155H20.5459C21.0982 15.155 21.5459 15.6027 21.5459 16.155V17.186C21.5459 17.7383 21.0982 18.186 20.5459 18.186H10.4219C9.86957 18.186 9.42186 17.7383 9.42186 17.186V16.155Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42186 22.217C9.42186 21.6648 9.86957 21.217 10.4219 21.217H17.0743C17.7718 21.217 18.255 21.9132 18.0111 22.5667L17.6263 23.5977C17.4804 23.9888 17.1069 24.248 16.6895 24.248H10.4219C9.86957 24.248 9.42186 23.8003 9.42186 23.248V22.217Z"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.283 27.8609C19.3959 27.8644 19.3959 27.8644 19.6364 27.8168L26.0962 26.0416L35.7936 17.31C36.3373 16.8205 36.5014 16.1164 36.1602 15.7374L32.3368 11.4911C31.9955 11.1121 31.2782 11.2017 30.7345 11.6913L21.0371 20.4228L18.6371 26.7069C18.3981 27.3257 18.6873 27.8424 19.283 27.8609ZM31.4087 15.0078L32.4447 16.1584L24.6692 23.1595L21.92 24.5932L23.6332 22.0089L31.4087 15.0078Z"
        fill="none"
      />
    </svg>
  )
}
export default YouApply
