const CartAnkerMake = ({ ...props }) => {
  return (
    <svg { ...props } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 0H0V24H24V0Z" fill="white" fillOpacity="0.01"/>
      <path d="M1.5 3H3.25L4 6L1.5 3ZM4 6L6.5 16H19.5L22 6H4Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7 22C8.10457 22 9 21.1046 9 20C9 18.8954 8.10457 18 7 18C5.89543 18 5 18.8954 5 20C5 21.1046 5.89543 22 7 22Z" fill="white"/>
      <path d="M19 22C20.1046 22 21 21.1046 21 20C21 18.8954 20.1046 18 19 18C17.8954 18 17 18.8954 17 20C17 21.1046 17.8954 22 19 22Z" fill="white"/>
    </svg>
  )
}
export default CartAnkerMake
