const Pinterest = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 100 100"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="pinterest">
        <path d="M86,34.61C86,54.89,74.33,70,57.17,70c-5.76,0-11.18-3-13-6.45,0,0-3.1,11.92-3.76,14.22-2.31,8.12-9.12,16.25-9.64,16.92a.72.72,0,0,1-1.27-.3c-.14-1-1.88-11.29.16-19.64L36.5,46.62a19.32,19.32,0,0,1-1.71-8.19c0-7.66,4.59-13.38,10.3-13.38,4.86,0,7.2,3.54,7.2,7.76,0,4.73-3.11,11.8-4.71,18.35-1.34,5.49,2.84,10,8.42,10,10.12,0,16.93-12.58,16.93-27.49,0-11.32-7.88-19.81-22.22-19.81-16.19,0-26.28,11.7-26.28,24.76A14.6,14.6,0,0,0,28,48.72c1,1.13,1.13,1.59.77,2.88l-1.09,4.15A1.85,1.85,0,0,1,25,57.05C17.49,54.1,14,46.19,14,37.3,14,22.61,26.8,5,52.18,5,72.57,5,86,19.29,86,34.61Z"></path>
      </g>
    </svg>
  )
}

export default Pinterest
