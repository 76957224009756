const Youtube = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="22734"
      width="200"
      height="200"
    >
      <path
        d="M448 640l192-128-192-128zM832 0H192a192 192 0 0 0-192 192v640a192 192 0 0 0 192 192h640a192 192 0 0 0 192-192V192a192 192 0 0 0-192-192z m-5.44 626.624c-3.456 42.624-35.776 97.024-81.088 104.896-144.832 11.2-316.736 9.856-466.88 0-46.848-5.888-77.632-62.336-81.088-104.896-7.296-89.472-7.296-140.416 0-229.888 3.456-42.56 35.008-98.688 81.088-103.808 148.416-12.48 321.152-9.792 466.88 0 52.224 1.92 77.632 55.616 81.088 98.24 7.232 89.472 7.232 145.984 0 235.456z"
        p-id="22735"
      ></path>
    </svg>
  )
}

export default Youtube
