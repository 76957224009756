const Card = ({ ...props }) => {
  return (
    <svg
      {...props}
      id="图层_1"
      data-name="图层 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <defs></defs>
      <path
        style={{ fill: '#222' }}
        d="M45.39,10.61H4.61A2.11,2.11,0,0,0,2.5,12.72V37.28a2.11,2.11,0,0,0,2.11,2.11H45.39a2.11,2.11,0,0,0,2.11-2.11V12.72A2.11,2.11,0,0,0,45.39,10.61ZM4.61,12H45.39a.7.7,0,0,1,.71.7V17H3.9V12.72A.7.7,0,0,1,4.61,12ZM45.39,38H4.61a.7.7,0,0,1-.71-.7V21.74H46.1V37.28A.7.7,0,0,1,45.39,38Z"
      />
      <rect
        x="34.23"
        y="27.22"
        width="9.09"
        height="5.79"
        rx="1.4"
        style={{ fill: 'none', stroke: '#222', strokeMiterlimit: 10 }}
      />
    </svg>
  )
}

export default Card
