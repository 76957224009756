const ArrowDownBlack = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    {...props}
  >
    <g opacity="0.6">
      <path
        d="M8.30762 11.6074L15.8076 19.1074L23.3076 11.6074"
        stroke="black"
        strokeWidth="1.57895"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)

export default ArrowDownBlack
