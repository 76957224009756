const TwitterNew = ({ ...props }) => {
  return (
    <svg {...props} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g id="twitter">
        <path d="M95,22.1A37,37,0,0,1,84.4,25a18.51,18.51,0,0,0,8.11-10.2,37.17,37.17,0,0,1-11.73,4.48A18.47,18.47,0,0,0,48.84,31.9a18.8,18.8,0,0,0,.48,4.21,52.42,52.42,0,0,1-38-19.29A18.49,18.49,0,0,0,17,41.47a18.63,18.63,0,0,1-8.36-2.32v.23a18.48,18.48,0,0,0,14.81,18.1,18.58,18.58,0,0,1-4.87.65,18.07,18.07,0,0,1-3.47-.34A18.48,18.48,0,0,0,32.33,70.61,37,37,0,0,1,9.4,78.5,40,40,0,0,1,5,78.25a52.23,52.23,0,0,0,28.3,8.3c34,0,52.53-28.13,52.53-52.52l-.06-2.39A37,37,0,0,0,95,22.1Z"></path>
      </g>
    </svg>
  )
}

export default TwitterNew
