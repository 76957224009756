const OrderHours = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2577_1308)">
        <path
          d="M7.90967 4.35498V9.32907H11.1884"
          stroke="#666666"
          strokeWidth="1.3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M0.532715 8.08545C0.532715 4.19737 3.65567 1.03882 7.49993 1.03882C11.3442 1.03882 14.4671 4.19737 14.4671 8.08545C14.4671 11.9735 11.3442 15.1321 7.49993 15.1321"
          stroke="#666666"
          strokeWidth="1.3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M4.63138 7.6709L2.17236 11.816H5.45105L2.17236 15.9611"
          stroke="#666666"
          strokeWidth="1.3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2577_1308">
          <rect
            width="15"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OrderHours
