const Instagram = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="8954"
      fill="#fff"
    >
      <path
        d="M610 512c0 54.126-43.876 98-98 98s-98-43.876-98-98 43.876-98 98-98 98 43.876 98 98z m0 0"
        p-id="8955"
      ></path>
      <path
        d="M741.188 338.61a91.092 91.092 0 0 0-21.992-33.806 91.028 91.028 0 0 0-33.806-21.992c-10.36-4.022-25.92-8.812-54.586-10.116-31.008-1.414-40.304-1.72-118.804-1.72-78.508 0-87.804 0.298-118.804 1.712-28.664 1.312-44.236 6.1-54.586 10.124a90.966 90.966 0 0 0-33.806 21.992 91.144 91.144 0 0 0-22 33.806c-4.022 10.36-8.812 25.93-10.116 54.594-1.414 31-1.72 40.296-1.72 118.804 0 78.5 0.306 87.796 1.72 118.804 1.304 28.664 6.094 44.228 10.116 54.586a91.126 91.126 0 0 0 21.992 33.806 91.028 91.028 0 0 0 33.806 21.992c10.36 4.03 25.93 8.82 54.594 10.124 31 1.414 40.288 1.712 118.796 1.712 78.516 0 87.812-0.298 118.804-1.712 28.664-1.304 44.236-6.094 54.594-10.124a97.36 97.36 0 0 0 55.798-55.798c4.022-10.36 8.812-25.92 10.124-54.586 1.414-31.008 1.712-40.304 1.712-118.804 0-78.508-0.298-87.804-1.712-118.804-1.304-28.664-6.094-44.236-10.124-54.594zM512 662.97c-83.382 0-150.976-67.588-150.976-150.97s67.594-150.968 150.976-150.968c83.376 0 150.968 67.586 150.968 150.968S595.376 662.968 512 662.968z m156.938-272.626c-19.484 0-35.28-15.798-35.28-35.28s15.796-35.282 35.28-35.282 35.28 15.796 35.28 35.28c-0.008 19.484-15.796 35.28-35.28 35.28z m0 0"
        p-id="8956"
      ></path>
      <path
        d="M512 0C229.274 0 0 229.274 0 512s229.274 512 512 512 512-229.274 512-512S794.726 0 512 0z m292.226 633.21c-1.42 31.298-6.398 52.666-13.664 71.368a150.328 150.328 0 0 1-85.984 85.984c-18.696 7.266-40.07 12.234-71.36 13.664-31.35 1.43-41.366 1.774-121.21 1.774-79.852 0-89.86-0.344-121.22-1.774-31.288-1.43-52.662-6.398-71.36-13.664a144.036 144.036 0 0 1-52.076-33.914 144.088 144.088 0 0 1-33.906-52.07c-7.266-18.696-12.242-40.07-13.664-71.36-1.446-31.36-1.782-41.374-1.782-121.218s0.336-89.86 1.774-121.21c1.42-31.298 6.39-52.664 13.656-71.368a144.026 144.026 0 0 1 33.92-52.07 144.006 144.006 0 0 1 52.072-33.914c18.704-7.266 40.07-12.234 71.368-13.664C422.14 218.344 432.156 218 512 218s89.86 0.344 121.21 1.78c31.298 1.424 52.664 6.392 71.368 13.65a144.122 144.122 0 0 1 52.08 33.92 144.054 144.054 0 0 1 33.904 52.072c7.274 18.704 12.242 40.07 13.672 71.368 1.43 31.35 1.766 41.366 1.766 121.21s-0.336 89.86-1.774 121.21z m0 0"
        p-id="8957"
      ></path>
    </svg>
  )
}

export default Instagram
