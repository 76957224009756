const ForLaptops = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="6539"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M868.224 695.68a5.504 5.504 0 0 1-5.504-5.504v-392.96a18.048 18.048 0 0 0-17.92-18.048H196.992A18.048 18.048 0 0 0 179.2 297.216v392.96a5.504 5.504 0 1 1-10.88 0v-392.96A28.928 28.928 0 0 1 196.992 268.8H844.8a28.928 28.928 0 0 1 28.928 28.928v392.96a5.376 5.376 0 0 1-5.504 4.992z"
        p-id="6540"
      ></path>
      <path
        d="M883.968 755.2H157.696a28.8 28.8 0 0 1-28.544-24.192l-6.144-37.12a5.504 5.504 0 0 1 5.376-6.4h784.896a5.376 5.376 0 0 1 4.096 1.92 5.248 5.248 0 0 1 1.28 4.48l-6.144 37.12A28.8 28.8 0 0 1 883.968 755.2z m-749.184-56.32l5.12 30.848a17.92 17.92 0 0 0 17.792 15.104h726.272A17.92 17.92 0 0 0 901.76 729.6l5.12-30.848z"
        p-id="6541"
      ></path>
      <path
        d="M610.56 723.84h-179.2a5.376 5.376 0 0 1-5.376-5.376v-22.272a5.376 5.376 0 0 1 5.376-5.376 5.376 5.376 0 0 1 5.376 5.376v16.896h168.448v-16.896a5.504 5.504 0 0 1 10.88 0v22.272a5.376 5.376 0 0 1-5.504 5.376zM572.672 307.2h-103.552a5.376 5.376 0 0 1-5.504-5.376v-24.96a5.504 5.504 0 0 1 10.88 0v18.944h92.672v-18.944a5.504 5.504 0 0 1 10.88 0v24.448a5.376 5.376 0 0 1-5.376 5.888z"
        p-id="6542"
      ></path>
    </svg>
  )
}

export default ForLaptops
