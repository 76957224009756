const Fast = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5422 3.625H8.03159C7.55407 3.625 7.16699 3.9981 7.16699 4.45833V13.625C7.16699 14.0853 7.55407 14.4583 8.03159 14.4583H17.5422"
        stroke="#777777"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33301 14.4583H7.16634V6.08203H4.24967L1.33301 9.15894V14.4583Z"
        stroke="#777777"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <path
        d="M7.16634 15.2916C7.16634 16.212 6.42013 16.9582 5.49967 16.9582C4.57922 16.9582 3.83301 16.212 3.83301 15.2916"
        stroke="#777777"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6825 15.2916C14.6825 16.212 13.9371 16.9582 13.0175 16.9582C12.098 16.9582 11.3525 16.212 11.3525 15.2916"
        stroke="#777777"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3281 7.71625H17.6387"
        stroke="#777777"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M14.3281 10.3037H19.5584"
        stroke="#777777"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Fast
