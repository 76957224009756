const USBOutlets = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="8673"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M507.264 739.2h-51.2a5.376 5.376 0 0 1-5.376-5.504v-25.6a30.72 30.72 0 0 1 61.44 0v25.6a5.504 5.504 0 0 1-4.864 5.504z m-45.056-10.88h39.68v-19.84a19.84 19.84 0 0 0-39.68 0z"
        p-id="8674"
      ></path>
      <path
        d="M650.624 891.392H313.344a49.024 49.024 0 0 1-48.896-48.896V200.832a49.024 49.024 0 0 1 48.896-48.896h337.28a48.896 48.896 0 0 1 48.896 48.896v641.664a48.896 48.896 0 0 1-48.896 48.896zM313.344 162.816a38.4 38.4 0 0 0-38.4 38.4v641.28a38.4 38.4 0 0 0 38.4 38.4h337.28a38.4 38.4 0 0 0 38.4-38.4V200.832a38.4 38.4 0 0 0-38.4-38.4z"
        p-id="8675"
      ></path>
      <path
        d="M584.448 776.32h-204.8a27.136 27.136 0 0 1-27.136-27.136V294.4a27.136 27.136 0 0 1 27.136-27.136h204.8A27.264 27.264 0 0 1 611.712 294.4v454.784a27.264 27.264 0 0 1-27.264 27.136z m-204.8-498.432A16.256 16.256 0 0 0 363.264 294.4v454.784a16.256 16.256 0 0 0 16.256 16.256h204.8a16.256 16.256 0 0 0 16.384-16.256V294.4a16.256 16.256 0 0 0-16.384-16.256z"
        p-id="8676"
      ></path>
      <path
        d="M438.528 445.184H396.8a5.504 5.504 0 0 1-5.504-5.504v-110.848a5.504 5.504 0 0 1 5.504-5.504h41.728a5.504 5.504 0 0 1 5.504 5.504v110.848a5.504 5.504 0 0 1-5.504 5.504z m-36.352-10.88h30.976v-100.096h-30.976zM567.296 445.184h-41.856a5.376 5.376 0 0 1-5.376-5.504v-110.848a5.376 5.376 0 0 1 5.376-5.504h41.856a5.376 5.376 0 0 1 5.376 5.504v110.848a5.376 5.376 0 0 1-5.376 5.504z m-36.352-10.88h30.848v-100.096h-30.848zM482.048 425.344a5.376 5.376 0 0 1-5.504-5.376v-71.424a5.504 5.504 0 0 1 10.88 0v71.424a5.376 5.376 0 0 1-5.376 5.376zM431.616 659.456a5.376 5.376 0 0 1-5.376-5.376v-66.176a5.504 5.504 0 0 1 10.88 0v66.176a5.376 5.376 0 0 1-5.504 5.376zM532.48 659.456a5.376 5.376 0 0 1-5.504-5.376v-66.176a5.504 5.504 0 0 1 10.88 0v66.176a5.376 5.376 0 0 1-5.376 5.376zM482.048 864.64a18.56 18.56 0 1 1 18.432-18.432 18.56 18.56 0 0 1-18.432 18.432z m0-25.6a7.68 7.68 0 1 0 7.552 7.68 7.68 7.68 0 0 0-7.552-8.192zM482.048 217.6a18.432 18.432 0 1 1 18.432-18.432A18.432 18.432 0 0 1 482.048 217.6z m0-25.6a7.68 7.68 0 1 0 7.552 7.552 7.68 7.68 0 0 0-7.552-7.552z"
        p-id="8677"
      ></path>
    </svg>
  )
}

export default USBOutlets
