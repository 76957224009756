const Play = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="19"
        fill="black"
        fillOpacity="0.3"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M28.8372 20L15.5814 29.2645L15.5814 10.7355L28.8372 20Z"
        fill="white"
      />
    </svg>
  )
}

export default Play
