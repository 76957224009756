const SolarGenerators = ({ ...props }) => {
  return (
    <>
      <style jsx>{`
        .b {
          fill: none;
          stroke: #000;
          strokelinecap: round;
          strokelinejoin: round;
          strokewidth: 0.76px;
        }
      `}</style>
      <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
        <g>
          <line className="b" x1="50.44" y1="55.46" x2="55.54" y2="55.46" />
          <g>
            <rect
              className="b"
              x="38.01"
              y="50.84"
              width="8.74"
              height="9.74"
              rx="1.25"
              ry="1.25"
            />
            <line className="b" x1="42.38" y1="53.83" x2="42.38" y2="57.58" />
          </g>
          <circle className="b" cx="63.6" cy="55.71" r="2.07" />
          <rect
            className="b"
            x="59.23"
            y="50.84"
            width="8.74"
            height="9.74"
            rx="1.25"
            ry="1.25"
          />
          <rect
            className="b"
            x="38.01"
            y="39.98"
            width="29.96"
            height="8.24"
            rx="1.25"
            ry="1.25"
          />
          <path
            className="b"
            d="M43.07,37.57v-2.96c0-.69,.56-1.25,1.25-1.25h17.35c.69,0,1.25,.56,1.25,1.25v2.96"
          />
          <line className="b" x1="43.07" y1="37.57" x2="62.92" y2="37.57" />
          <rect
            className="b"
            x="34.89"
            y="37.61"
            width="36.2"
            height="25.97"
            rx="2.19"
            ry="2.19"
          />
        </g>
        <line className="b" x1="47.04" y1="22.28" x2="47.04" y2="33.36" />
        <path
          className="b"
          d="M34.89,57.6l-6.7-1.22c-.25-.05-.51-.04-.76,0l-14.7,2.93c-1.26,.25-2.43-.71-2.43-1.99V23.95c0-.97,.68-1.8,1.63-1.99l15.5-3.09c.25-.05,.51-.05,.76,0l18.47,3.35c.25,.05,.51,.04,.76,0,0,0,12.72-2.5,16.46-3.18,.54-.1,1.02,.31,1.03,.86l.11,17.73"
        />
        <line className="b" x1="27.85" y1="18.94" x2="27.85" y2="56.02" />
        <polyline
          className="b"
          points="44.84 33.36 44.84 24.54 29.85 21.67 29.85 53.89 34.89 54.86"
        />
        <polyline
          className="b"
          points="49.14 33.36 49.14 24.54 62.53 21.67 62.53 33.71"
        />
        <path
          className="b"
          d="M18.29,45.56l-1.34,.29c-1.26,.27-2.46-.69-2.46-1.99v-8.85c0-.96,.67-1.79,1.61-1.99l1.34-.29c1.26-.27,2.46,.69,2.46,1.99v8.85c0,.96-.67,1.79-1.61,1.99Z"
        />
      </svg>
    </>
  )
}

export default SolarGenerators
