const Warranty = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.93375 8.18607L9.75087 8.6421L10.0959 8.9919L13.9473 12.8967C14.0419 12.9926 14.118 13.1079 14.1703 13.2364C14.2227 13.3649 14.2499 13.5036 14.25 13.6441C14.2501 13.7846 14.223 13.9232 14.1708 14.0518C14.1186 14.1804 14.0426 14.2958 13.9482 14.3918L13.8933 14.4474L13.8932 14.4475C13.4954 14.8508 12.8467 14.8508 12.4489 14.4475L12.4488 14.4474L8.67022 10.6181L8.29422 10.2371L7.81166 10.4689C7.26084 10.7334 6.64794 10.8815 5.99767 10.8815C3.66054 10.8815 1.75001 8.95823 1.75001 6.56652L1.75001 6.56515C1.74934 6.19951 1.79456 5.83709 1.88333 5.48657L3.92526 7.55605L3.92676 7.55756C4.15777 7.79035 4.4727 7.9244 4.8049 7.9244C5.13711 7.9244 5.45204 7.79035 5.68304 7.55756L5.68451 7.55607L6.98639 6.23677C6.98675 6.23641 6.98711 6.23605 6.98746 6.23568C7.10256 6.11947 7.19295 5.98202 7.25435 5.83183L7.25436 5.83183C7.31594 5.6812 7.34741 5.52017 7.34741 5.35797C7.34741 5.19577 7.31594 5.03474 7.25436 4.88412L7.25435 4.88411C7.19293 4.73388 7.10249 4.59637 6.98734 4.48014C6.98702 4.47982 6.9867 4.47949 6.98639 4.47917L4.92364 2.38858C5.26611 2.29818 5.62524 2.25 5.99583 2.25C8.33297 2.25 10.2435 4.1733 10.2435 6.56501C10.2435 7.14047 10.1334 7.68824 9.93375 8.18607ZM1.60864 4.14034C1.48047 4.3778 1.37186 4.62476 1.28346 4.87861L2.16415 4.70334L1.60864 4.14034Z"
        stroke="#666666"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default Warranty
