const Email = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21.0029 20C21.5552 20 22 19.5523 22 19V6C22 5.44772 21.5523 5 21 5H12H3C2.44772 5 2 5.44772 2 6V12.5V19C2 19.5523 2.44384 20 2.99613 20C6.24154 20 16.5568 20 21.0029 20Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7V7C7.20417 13.245 16.7958 13.245 22 7V7"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Email
