const Year5 = ({ ...props }) => {
  return (
    <svg
      {...props}
      id="图层_1"
      data-name="图层 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <defs></defs>
      <path
        className="cls-1"
        d="M43,10.11a2,2,0,0,0-1.41-.57A24.43,24.43,0,0,1,26.24,4.17a2,2,0,0,0-2.48,0A24.41,24.41,0,0,1,8.37,9.54a2,2,0,0,0-2,2v9.73c0,12.35,4.8,20.08,15.12,24.33a9.27,9.27,0,0,0,7,0c10.32-4.25,15.12-12,15.12-24.33V11.52A2,2,0,0,0,43,10.11Zm-.88,11.14c0,11.68-4.52,19-14.22,23a7.77,7.77,0,0,1-5.88,0c-9.7-4-14.22-11.29-14.22-23V11.52A.51.51,0,0,1,8,11.16.53.53,0,0,1,8.38,11a25.87,25.87,0,0,0,16.3-5.69.52.52,0,0,1,.64,0A25.88,25.88,0,0,0,41.63,11a.56.56,0,0,1,.38.15.51.51,0,0,1,.15.36Z"
      />
      <path
        className="cls-1"
        d="M25.37,21.6a4.82,4.82,0,0,0-2.94.88h-.09V18a.16.16,0,0,1,.18-.18h7.73a.27.27,0,0,0,.29-.29V15.22a.28.28,0,0,0-.29-.3H19.61a.28.28,0,0,0-.3.3V25.89a.28.28,0,0,0,.3.29h2.47c.2,0,.26-.09.35-.29a2.59,2.59,0,0,1,2.44-1.37,2.39,2.39,0,0,1,2.5,1.63,6.38,6.38,0,0,1,.26,2.19,6,6,0,0,1-.26,2.19,2.46,2.46,0,0,1-2.47,1.63,2.73,2.73,0,0,1-2.76-1.89.3.3,0,0,0-.36-.24l-2.5.61a.32.32,0,0,0-.23.35c.44,2.45,2.76,4.09,5.85,4.09,2.65,0,4.88-1.32,5.64-3.62A9.74,9.74,0,0,0,31,28.34,10.68,10.68,0,0,0,30.52,25C29.81,22.8,27.93,21.6,25.37,21.6Z"
      />
    </svg>
  )
}
export default Year5
