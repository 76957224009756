const Success = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill="#1D1D1F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1948 5.76027C11.9351 5.50048 11.5139 5.50048 11.2541 5.76027L7.13179 9.88255L4.61411 7.36487C4.35433 7.10509 3.93314 7.10509 3.67335 7.36487C3.41357 7.62465 3.41357 8.04585 3.67335 8.30563L6.60631 11.2386C6.61422 11.2465 6.62229 11.2542 6.63049 11.2616C6.64022 11.2727 6.65037 11.2836 6.66096 11.2941C6.92074 11.5539 7.34193 11.5539 7.60172 11.2941L12.1948 6.70103C12.4546 6.44124 12.4546 6.02005 12.1948 5.76027Z"
        fill="white"
      />
    </svg>
  )
}

export default Success
