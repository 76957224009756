const Ins = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="22468"
      width="200"
      height="200"
    >
      <path
        d="M821.628458 392.600791V348.079051c0-16.189723-4.047431-32.379447-8.094861-48.56917-4.047431-28.332016-20.237154-48.56917-36.426878-68.806324-8.094862-8.094862-20.237154-16.189723-32.379446-24.284585-20.237154-8.094862-44.521739-16.189723-68.806324-16.189723H380.458498l-48.56917 4.047431c-12.142292 0-24.284585 4.047431-36.426877 8.094862-16.189723 4.047431-28.332016 8.094862-40.474309 20.237154-24.284585 16.189723-40.474308 40.474308-48.56917 68.806324-8.094862 16.189723-12.142292 32.379447-12.142292 52.616601V639.494071c0 16.189723 0 28.332016 4.047431 44.521739 0 12.142292 4.047431 28.332016 8.094861 40.474309 4.047431 20.237154 16.189723 40.474308 32.379447 56.664031 12.142292 12.142292 28.332016 24.284585 48.56917 28.332016 20.237154 8.094862 40.474308 12.142292 64.758893 12.142292h303.557312c12.142292 0 28.332016 0 40.474309-4.04743 12.142292 0 28.332016-4.047431 40.474308-8.094862 32.379447-12.142292 60.711462-36.426877 72.853755-64.758893 8.094862-20.237154 16.189723-44.521739 16.189723-68.806324v-40.474309-76.901185-137.612649c-4.047431-16.189723-4.047431-20.237154-4.047431-28.332015z m-315.699604 311.652173c-109.280632 0-198.324111-89.043478-198.324111-198.32411v-4.047431c4.047431-109.280632 89.043478-194.27668 198.324111-194.27668s198.324111 89.043478 198.32411 198.324111-89.043478 198.324111-198.32411 198.32411z m206.418972-356.173913c-28.332016 0-48.56917-20.237154-48.56917-44.521739 0-24.284585 20.237154-44.521739 44.521739-44.521739 24.284585 0 44.521739 20.237154 44.521739 44.521739 4.047431 20.237154-16.189723 44.521739-40.474308 44.521739z"
        p-id="22469"
      ></path>
      <path
        d="M854.007905-5.41798941H169.992095C76.901186-5.41798941 0 71.48319659 0 164.57410559v684.01580999c0 93.090909 76.901186 169.992095 169.992095 169.99209501h684.01581c93.090909 0 169.992095-76.901186 169.992095-169.99209501V164.57410559c0-93.090909-76.901186-169.992095-169.992095-169.992095z m36.426878 619.25691699v48.56917c0 24.284585-4.047431 48.56917-12.142293 68.80632401-8.094862 24.284585-20.237154 48.56917-36.426877 68.806324-24.284585 28.332016-56.664032 52.616601-93.090909 64.75889299l-48.56917 12.142293c-16.189723 0-28.332016 4.047431-44.52174 4.04743101H339.98419c-28.332016 0-52.616601-4.047431-80.948617-16.18972401-64.758893-20.237154-113.328063-72.853755-129.517786-141.66007899-4.047431-16.189723-8.094862-28.332016-8.094862-44.521739 0-12.142292-4.047431-28.332016-4.047431-40.47430801v-40.474308-178.08695699-36.426877-48.56917001c0-24.284585 4.047431-52.616601 12.142293-76.901186 16.189723-24.284585 32.379447-52.616601 52.6166-72.853755 24.284585-24.284585 52.616601-40.474308 80.948617-52.616601 20.237154-4.047431 36.426877-8.094862 52.616601-12.14229199 12.142292 0 24.284585-4.047431 40.474308-4.047431h315.699605c32.379447 0 64.758893 8.094862 93.090909 20.237154 24.284585 12.142292 44.521739 24.284585 60.711462 44.52173899 24.284585 28.332016 40.474308 60.711462 48.56917 97.13834 4.047431 16.189723 8.094862 32.379447 8.094862 48.56917001v246.89328099c8.094862 12.142292 8.094862 28.332016 8.094862 40.474308z"
        p-id="22470"
      ></path>
      <path
        d="M509.976285 376.411067c-72.853755 0-129.517787 56.664032-129.517787 125.470356 0 68.806324 56.664032 129.517787 129.517787 129.517786 68.806324 0 129.517787-56.664032 129.517786-129.517786-4.047431-64.758893-60.711462-121.422925-129.517786-125.470356z"
        p-id="22471"
      ></path>
    </svg>
  )
}

export default Ins
