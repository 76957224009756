const Twitter = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="22868"
      width="200"
      height="200"
    >
      <path
        d="M807.37526539 354.60957775v22.1385802c0 231.87249601-164.29156689 499.86583293-466.07536903 499.86583295-92.04988506 0-178.27382888-29.12970994-250.51551071-79.23281284 12.81707222 2.33037699 25.63414572 2.33037699 39.61640639 2.330377 76.90243583 0 146.81374067-27.96452271 202.74278485-75.73724733C262.06708353 723.97430773 201.4772854 673.87120483 180.5038937 603.95989997c10.48669522 2.33037699 19.80820322 3.4955655 31.46008818 3.49556549 15.1474492 0 29.12970994-2.33037699 41.94678344-5.82594247-74.57205883-16.31263772-130.50110295-87.38913105-130.50110423-172.44788639v-2.33037701c22.13858021 12.81707222 47.77272464 20.97339171 74.5720588 22.13858021-44.27716042-31.46008693-73.40687035-85.05875533-73.40687034-146.81374068 0-31.46008693 8.15631948-61.75498661 23.30376871-87.38913234 80.39800133 106.03214575 201.57759631 175.9434519 336.73945329 182.93458288-3.4955655-12.81707222-4.66075399-25.63414572-4.66075273-39.61640641 0-96.71063907 73.40687035-175.9434519 163.12637839-175.94345189 46.60753742 0 89.71950807 20.97339171 120.01440776 54.76385564 37.28602944-6.99113101 72.24168187-22.13858021 103.70176876-41.94678342-11.65188373 40.78159492-38.45121793 75.73724734-71.07649334 96.71063902 32.62527542-3.4955655 64.08536363-13.98226071 94.38026206-27.96452142-23.30376871 36.1208409-51.26829014 66.41574064-82.72837706 90.88469657z m44.27715915-357.71284502H172.34757546c-93.21507356 0-170.11750938 75.73724734-170.1175094 170.1175094v679.30484908c0 94.38026205 76.90243583 170.11750938 170.1175094 170.1175094h679.30484908c94.38026205 0 170.11750938-75.73724734 170.1175094-170.1175094V167.01424213c0-94.38026205-75.73724734-170.11750938-170.1175094-170.1175094z"
        p-id="22869"
      ></path>
    </svg>
  )
}

export default Twitter
