const Question = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#C4C4C4" />
      <path
        d="M7.08884 9.91601H8.63684C8.45684 8.24801 11.0728 8.044 11.0728 6.28001C11.0728 4.68401 9.80084 3.88 8.06084 3.88C6.77684 3.88 5.72084 4.45601 4.96484 5.34401L5.94884 6.25601C6.53684 5.63201 7.12484 5.30801 7.86884 5.30801C8.80484 5.30801 9.38084 5.716 9.38084 6.436C9.38084 7.60001 6.83684 8.03201 7.08884 9.91601ZM7.86884 13.108C8.48084 13.108 8.92484 12.676 8.92484 12.052C8.92484 11.416 8.48084 10.984 7.86884 10.984C7.26884 10.984 6.81284 11.416 6.81284 12.052C6.81284 12.676 7.25684 13.108 7.86884 13.108Z"
        fill="white"
      />
    </svg>
  )
}

export default Question
