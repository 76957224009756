const FastAirPlane = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...props}
        d="M7.66232 14.7338L7.87863 14.5126L7.8761 14.2032L7.85223 11.2823L9.75746 9.66033L11.3048 14.7194L11.6751 15.93L12.559 15.0236L13.083 14.4862L13.3209 14.2423L13.2937 13.9027L12.7415 7.01519L12.955 6.80101L13.6388 6.1153L14.7306 5.02041L14.7307 5.02044L14.7366 5.01437C15.3568 4.3782 15.6112 3.77962 15.7034 3.3355C15.7586 3.06957 15.761 2.83771 15.7302 2.6431C15.715 2.54684 15.6912 2.4574 15.6601 2.37663C15.6395 2.32314 15.5871 2.19565 15.479 2.08082L15.4699 2.07111L15.4604 2.06174C15.3384 1.94109 15.2006 1.88045 15.1267 1.85186C15.0348 1.81626 14.9371 1.79127 14.8393 1.77499C14.6421 1.7422 14.4096 1.73897 14.1557 1.78161C13.6381 1.86852 13.0435 2.14345 12.4676 2.72905C12.4676 2.72911 12.4675 2.72918 12.4675 2.72924C12.4674 2.72927 12.4674 2.72931 12.4673 2.72935L12.4662 2.73051L10.4991 4.70842L3.60595 4.01119L3.24592 3.97478L2.99336 4.23393L2.46289 4.77823L1.61586 5.64734L2.77204 6.01621L7.87612 7.6446L6.24767 9.65183L3.28719 9.50618L2.9484 9.48952L2.71215 9.73292L2.60872 9.8395L1.85148 10.6197L2.84975 11.0505L5.40312 12.1523L6.3349 14.5722L6.75451 15.662L7.571 14.8271L7.66232 14.7338ZM7.69675 11.4146L7.69702 11.4144C7.6969 11.4145 7.69678 11.4146 7.69666 11.4147L7.69675 11.4146ZM12.5992 7.15787L12.5995 7.15754C12.5994 7.15766 12.5993 7.15778 12.5991 7.15791L12.5992 7.15787ZM10.708 4.72955L10.7075 4.7295L10.708 4.72955Z"
        stroke="#666666"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default FastAirPlane
